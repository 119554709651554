import React, { useEffect, useState } from "react";

export default function Countdown(props) {
    const calculateTimeLeft = () => {
      const difference = Date.UTC('2022', '10', '11', '00') - Date.now()
      let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            d: Math.floor(difference / (1000 * 60 * 60 * 24)),
            h: Math.floor((difference / (1000 * 60 * 60)) % 24),
            m: Math.floor((difference / 1000 / 60) % 60),
            s: Math.floor((difference / 1000) % 60),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
      const [year] = useState(new Date().getFullYear());
    
      useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      });
    
      const timerComponents = [];
    
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
    
        timerComponents.push(
          <span>
            {timeLeft[interval]}{interval}{" "}
          </span>
        );
      });
      return (
        <div 
        style={{
          color: '#fff',
        }}>
          {/* 
          Round Ends in: {timerComponents.length ? timerComponents : <span>Ended</span>}
          */}
        </div>
      );
}
    