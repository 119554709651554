import * as React from 'react'
import { ActivityIndicator, Animated, StyleSheet, Text, View } from 'react-native';

import GameContext from '../../context/GameContext';


import { useDebounce } from 'use-debounce'
import { ethers, utils } from 'ethers'
import Button from '../Button';

import useDimensions from '../../src/hooks/useDimensions';
import Images from "../../src/Images";


export default function MetaBox(props) {
  
  const [to, setTo] = React.useState('')
  const [debouncedTo] = useDebounce(to, 500)
 
  const [amount, setAmount] = React.useState('0.0011')
  const [debouncedValue] = useDebounce(amount, 500)

  const [block, setBlock] = React.useState(0)
  const [account, setAccount] = React.useState()
  const [bal, setBal] = React.useState(0)

  const [done, setDone] = React.useState(false)

  const [loading, setLoading] = React.useState(false)
  const [hash, setHash] = React.useState()

  const [pond, setPond] = React.useState(0)
  


  const shorten = (parsed, chars) =>  {
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
  }
 
const saveScore = async () => {
    setLoading(true)
    const provider = new ethers.providers.Web3Provider(window.ethereum)

    let receiverAddress = '0x50d54087bD6ae3AC4D5b6C31F7a89F0aa2849E1c'
    // Ether amount to send
    let dd = new Date().getTime()
    
    let amountInEther = '0.0011'
    // Create a transaction object
    let tx = {
        to: receiverAddress,
        gasLimit: 100000,
        // Convert currency unit from ether to wei
        value: ethers.utils.parseEther(amountInEther),
        data: ethers.utils.formatBytes32String(`meta${props.score}${dd}${props.score}`)

    }
    // Send a transaction
    console.log(account)
    const signer = await provider.getSigner(account)

    console.log(signer)
    
    signer.sendTransaction(tx)
    .then((txObj) => {
        console.log('txHash', txObj.hash)
        setHash(txObj.hash)
        setDone(true)
        setLoading(false)
        alert('Success!')
        // => 0x9c172314a693b94853b49dc057cf1cb8e529f29ce0272f451eea8f5741aa9b58
        // A transaction result can be checked in a etherscan with a transaction hash which can be obtained here.
    })
    

}



 const  connectToMetamask = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts", []);
    const balance = await provider.getBalance(accounts[0]);
    setAccount(accounts[0])
    const balanceInEther = ethers.utils.formatEther(balance);

    setBal(balanceInEther)

    const block = await provider.getBlockNumber();
    provider.on("block", (blockno) => {
        setBlock(blockno)
    })
   // const daiContract = new ethers.Contract('0x6b175474e89094c44da98b954eedeac495271d0f', ERC20_ABI, provider);
   // const tokenName = await daiContract.name();
   // const tokenBalance = await daiContract.balanceOf(accounts[0]);
    //const tokenUnits = await daiContract.decimals();
    //const tokenBalanceInEther = ethers.utils.formatUnits(tokenBalance, tokenUnits);
    //pond
    //this.setState({ selectedAddress: accounts[0], balance: balanceInEther, block, tokenName, tokenBalanceInEther })
  }

  /*
  const { config } = usePrepareSendTransaction({
    request: {
      to: debouncedTo,
      value: debouncedValue ? utils.parseEther(debouncedValue) : undefined,
    },
  })
  */
 
  return (
    <form
    style={{ 
        display: 'flex',
        flexDirection: 'column',
     }}
     >
         <Text style={styles.twhite} numberOfLines={4}>
            0.1 $POND or 0.0011 ETH
         </Text>
    {account &&
    <div style={{display: 'flex', flexDirection: 'column'}}>
     <Text style={styles.taddy} numberOfLines={1}>
        You: {shorten(account, 4)}
    </Text>
    <Text style={styles.taddy} numberOfLines={1}>
    ↓
    </Text>
    {!hash &&
    <Text style={styles.taddy} numberOfLines={1}>
    Score: {props.score}
    </Text>
    }
    {hash &&
    <Text style={styles.taddy} numberOfLines={1}>
        <a style={{color:'white'}} href={`https://etherscan.io/tx/${hash}`}>Saving: {shorten(hash, 4)}</a>
    </Text>
    }   

    </div>
    }
      {!account &&
       <Button
            key="b3"
            onPress={() => { connectToMetamask() } }
            txtStyle={styles.txtStyle}
            text="Connect"
        />
        }
    {loading &&
    <div style={{marginTop: 5, marginBottom: 5}}>
            <ActivityIndicator size="large" color="#0000ff" />
            </div>
    }
     {account &&
     !loading &&
     !done &&
     <div style={{display: 'flex', justifyContent: 'space-around'}}>
       <Button
            key="b3"
            onPress={() => { saveScore() } }
            txtStyle={styles.txtStyleb}
            text="ETH"
            style={{ opacity: bal > 0.0011 ? 1 : 0.2}}
        />
         <Button
            key="b3"
            onPress={() => { alert('disabled') } }
            txtStyle={styles.txtStyleb}
            style={{ opacity: '0.2'}}
            text="POND"
        />
    </div>
        }
      
    </form>
  )
}


const styles = StyleSheet.create({
    taddy:{
        fontSize: 15,
        marginTop: 10,
        color: '#fff',
        textAlign: 'center',
        opacity: 0.8
    },
    twhite:{
        fontSize: 15,
        marginTop: 10,
        color: '#fff',
        textAlign: 'center',
      },
      txtStyleb: {
        fontSize: 17,
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#0098ff',
        color: 'white',
        marginTop: 9,
        fontFamily: 'retro',
        height: 50,
        width: 100,
        paddingTop: 15,
        marginBottom: 25,
        marginRight: 5
      },
      txtStyle: {
        fontSize: 17,
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#ba85ff',
        color: 'white',
        marginTop: 9,
        fontFamily: 'retro',
        height: 40,
        width: 200,
        paddingTop: 10,
        marginBottom: 25,
      },
    text: {
      fontSize: 23,
      color: '#72E374',
      fontFamily: 'retro',
      textAlign: 'center',
      marginTop: 50
    },
    banner: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      backgroundColor: 'red',
      height: 90,
      paddingHorizontal: 8,
      marginVertical: 0,
    },
    button: {
      marginTop: 5,
      fontFamily: 'retro',
      fontSize: 23,

      height: 60,
      width: 200,
      marginBottom: 25,
      backgroundColor: '#ba85ff',
      color: 'white'
    },
  });