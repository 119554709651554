import RailRoad from './RailRoad';
import Road from './Road';
import Water from './Water';
import Grass from './Grass';

export default {
  RailRoad,
  Road,
  Water,
  Grass,
};
